<template>
	<div class="Agreement w">
		<!-- 面包屑 start -->
		<div class="bread flex align-center font-33 ptb24">
			您现在的位置：<el-breadcrumb
				separator="/"
				separator-class="el-icon-arrow-right"
			>
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 面包屑 end -->
		<div class="con-wrap bgfff br4">
			<div class="tit fs18 fw-7 font-33 pr00 pb24 flex">
				<div class="tit-line">
					<span class="pr">{{ title }}</span>
					<span class="line">{{ title }}</span>
				</div>
			</div>
			<div class="con pt32" v-loading="loading" v-html="content"></div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Agreement",
	data() {
		return {
			title: "",
			content: "",
			loading: true,
			id: "",
		};
	},
	created() {
		this.$pageScrollTop();
		let { id = 0 } = this.$route.query;
		//	this.id = this.$route.query.id;
		//	console.log(this.id);
		this.getData(id);

		switch (Number(id)) {
			case 124:
				this.title = "关于我们";
				break;
			case 15:
				this.title = "用户注册协议";
				break;
			case 16:
				this.title = "隐私与政策";
				break;
		}
	},
	watch: {
		$route() {
			this.id = this.$route.query.id;
		},
		id() {
			this.getData(this.id);
			//	console.log("重新请求" + this.userId + "的数据");
			switch (Number(this.id)) {
				case 124:
					this.title = "关于我们";
					break;
				case 15:
					this.title = "用户注册协议";
					break;
				case 16:
					this.title = "隐私与政策";
					break;
			}
		},
	},
	methods: {
		getData(category_id) {
			this.$API.getAgreement({ category_id }).then((res) => {
				this.loading = false;
				if (res.code == 1) {
					this.content = res.data.content;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
	},
};
</script>
<style scoped lang="scss">
.Agreement {
	@extend .h100;
	padding-bottom: 64px;

	.con-wrap {
		padding: 32px 48px;

		.tit {
			border-bottom: 1px dashed #eeeeee;
		}

		.con {
			min-height: 500px;
		}
	}
}
</style>
